.card-container {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem; /* Adds space between cards */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .card-container .card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .card-container .card-content span {
    font-size: 18px;
    color: #565656;
  }
  
  .card-container .actions {
    display: flex;
    align-items: center;
  }
  
  .card-container .delete-icon {
    color: red;
    height: 24px;
    width: 25px;
    margin-left: 1rem;
    cursor: pointer;
  }
  
  .card-container:hover{
    cursor: pointer;
  }