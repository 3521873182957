.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup-header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}

.popup-input,
.popup-select {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.popup-select {
  background-color: #fff;
  cursor: pointer;
}

.popup-select:focus {
  border-color: #007bff;
}

.popup-buttons {
  display: flex;
  justify-content: space-around;
}

.popup-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup-button.save {
  background-color: green;
  color: white;
}

.popup-button.cancel {
  background-color: red;
  color: white;
}

.error-message {
  color: red;
  margin-top: 5px;
}
