/* General Styles */
* {
  scrollbar-width: auto;
  scrollbar-color: #24D366 #f8f9fa;
}

/* For WebKit browsers (like Chrome, Safari) */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #023a6b;
  border-radius: 5px;
}

*::-webkit-scrollbar-track {
  background-color: #f8f9fa;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

/* Dashboard Container */
.dashboard-container {
  display: flex;
  height: 100vh;
  background-color: #f1f1f1;
}

/* Sidebar */
.sidebar {
  width: 15%;
  background-color: aliceblue;
  padding: 15px;
  
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar.collapsed {
  width: 4.5%;
  border-radius: 10px;
  background-color: #25D366;
  margin: 10px;
}
.sidebar {
  position: relative; /* Ensure positioning context */
}
.collapse-toggle {
  position: absolute;
  top: 35px; /* Distance from the top of the sidebar */
  right: -10px; /* Initial positioning outside the sidebar */
  /* width: 20px; */
  height: 20px;
  background-color: #A66CFF; /* Background color of the bubble */
  border-radius: 50%; /* Bubble shape */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 0 5px rgb(255, 255, 255); /* Optional shadow for better visibility */
  z-index: 100; /* Ensure it’s above other elements */
  
}

.collapse-toggle svg {
  color: white; /* Arrow color */
  font-size: 6px; /* Arrow size */
}


.menu-icon-text {
  display: flex;
  align-items: center;
}

.menu-icon-text img {
  margin-right: 10px;
  margin-left: 2px;
}

.submenu {
 padding: 8px;
}

.submenu.collapsed {
  display: none;
}

.menu-item, .submenu-item {
  position: relative;
  cursor: pointer;
}

.tooltip {
  display: none;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateX(10px) translateY(-50%);
  padding: 5px;
  background-color: #333;
  color: white;
  border-radius: 3px;
  font-size: 14px;
  white-space: nowrap;
  z-index: 1000;
}
.menu-item:hover .tooltip, .submenu-item:hover .tooltip {
  display: block;
}

.collapse-toggle {
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Logo Section */
.logo-section {
  padding: 20px;
  display: flex;
  align-items: center;
}

.logo {
  width: 40px;
  margin-right: 10px;
}

/* Menu */
.menu {
  margin-top: 20px;
  
}

.menu-item {
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
 
}

.menu-item:hover {

  background-color: #1e1e1e63;
}

.menu-icon-text {
  display: flex;
  align-items: center;
}

.menu-icon-text span {
  margin-left: 10px;
}



.submenu-item {
  padding: 7px 0;
  display: flex;
  cursor: pointer;
  align-items: center;
}
.submenu-item:hover{
  
  background-color: #1e1e1e63;
}

.submenu-item span {
  margin-left: 10px;
}

/* Main Content */
.main-content {
  flex-grow: 1;
  height: 90vh;
  /*margin-top: 10px; */
  background-color: 'none';
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 9%;
  border-radius: 10px;
  background-color: #25D366;
  margin: 10px;
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.right-screen {
  
 
  
  padding: 10px;
  height: 85vh;
  border-radius: 10px;
  width: auto;
  background-color: #ffffff;
  margin: 10px;
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.left-section {
  display: flex;
  align-items: center;
}

.screen-name {
  padding-left: 5px;
  font-size: 24px;
  color: white
}

.icon-group {
  display: flex;
  gap: 10px;
  cursor: pointer;
  font-size: 1.5rem;
  padding-left: 10px;
}

.profile-section {
  display: flex;
  align-items: center;
}

.notification-icon {
  margin-right: 10px;
  cursor: pointer;
}

.profile-info {
  display: flex;
  align-items: center;
}

.profile-text {
  margin-left: 10px;
}

.profile-role {
  color: gray;
  font-size: 12px;
}

.background {
  background-image: url(/src/pages/components/images/AWS.png);
  background-repeat: no-repeat;
}

.pos {
  position: relative;
  left: 9.3rem;
  top: 8rem;
}

.position {
  position: relative;
  top: 80%;
  margin-left: -7rem;
}

.Theme {
  background-color: aliceblue;
  color: rgb(50, 52, 52);
}

.Theme1 {
  background-color: rgb(6, 6, 39);
  opacity: 0.9;
  color: aliceblue;
}

.Theme2 {
  background-color: rgb(50, 52, 52);
  color: aliceblue;
}

.Theme3 {
  background-color: white;
  color: rgb(50, 52, 52);
}

.textcolor {
  color: rgb(50, 52, 52);
}

.Textcolor {
  color: rgb(50, 52, 52);
}

.alicebg {
  background-color: aliceblue;
}

textarea::placeholder {
  color: rgb(50, 52, 52);
}

.cursor {
  cursor: pointer;
}

.goldenrod {
  color: goldenrod;
}

.textcolor {
  color: aliceblue;
}

.bottom {
  border-bottom: 1px solid transparent;
}

.bottom:hover {
  border-bottom: 1px solid white;
}

.border {
  border-right: 3px solid rgb(50, 52, 52);
}

.height-30 {
  height: 40vh;
  background-color: rgb(9, 9, 54);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.height-70 {
  height: 60vh;
  background-color: aliceblue;
  color: black;
  margin: 0;
  padding: 0;
}

.input {
  border: none; /* Light border for the glass effect */
  border-radius: 0.4rem;
  padding: 0;
  background-color: transparent; /* Semi-transparent background */
  color: black;
  backdrop-filter: blur(10px); /* Frosted glass effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}


.inputtemp {
  border: 1px solid rgb(50, 52, 52);
  border-radius: 0.4rem;
  padding: 0.3rem;
  background-color: white;
  color: rgb(50, 52, 52);
  padding-left: 4px;
}

.in {
  width: 150%;
  background-color: aliceblue;
}

.float {
  float: right;
}

.input:hover, .inputtemp:hover {
  border: none
}

.inpu {
  border: 2px solid goldenrod;
  width: 180%;
  border-radius: 0.4rem;
  padding: 0.3rem;
}

.placeholde::placeholder {
  text-align: center;
}

.widt {
  width: 75%;
}

.wid {
  width: 30%;
}

.marginx {
  margin-inline: 33rem;
}

.margin {
  margin-inline: 5.8rem;
}

.guest {
  width: 15%;
}

.shadow {
  border: 1px solid transparent;
}

.shadow:hover {
  border: 1px solid rgb(191, 188, 188);
}

.center {
  position: absolute;
  top: 43%;
  left: 50%;
}

@media screen and (max-width: 1200px) and (min-width: 768px) {
  .marginx {
    margin-inline: auto;
    align-items: center;
  }
  .wid {
    width: 40%;
  }
  .widt {
    width: 70%;
  }
  .position {
    margin-left: 6rem;
  }
}

@media screen and (max-width: 768px) {
  .guest {
    width: 30%;
  }
  .widt {
    width: 75%;
  }
  .marg {
    margin-left: 3rem;
  }
  .marginx {
    margin-inline: auto;
    align-items: center;
  }
  .wid {
    width: 50%;
  }
  .position {
    margin-left: 6rem;
  }
  .small {
    background-color: rgb(50, 52, 52);
    color: aliceblue;
  }
}

.filter-option {
  cursor: pointer;
  margin-bottom: 5px;
  transition: background-color 0.3s ease;
}

.filter-option:hover {
  background-color: #f0f0f0;
}

.selected {
  background-color: #e1e1e1;
}

/* .form-control:focus {
  border-color: green !important;
  box-shadow: 0 0 0 0.25rem rgba(0, 128, 0, 0.25) !important;
} */