.blue{
    background-color: rgb(9, 9, 54);
    color: goldenrod;

}

.white{
    background-color: aliceblue;
    color:rgb(50, 52, 52)
}

.scroll{
    height: 100%;
   
    overflow: scroll;
    scrollbar-width: none;
}
.scrolll{
    height: 100vh;
   
    overflow: scroll;
    scrollbar-width: none;
}

.scrol{
    height: 65%;
   
    overflow: scroll;
    scrollbar-width: none;

}
.scro{
    height: 10%;
    
    overflow: scroll;
    scrollbar-width: none;

}

.back{
    display: flex;
    align-items: center;
    justify-content: center;
}
.form{
    display: flex;
    flex-direction: column;
  gap: 1.5rem;
margin-inline: 4rem;
}

.form_check_input:checked {
    border-color: goldenrod;
    background-color: goldenrod;
    width: 1.2rem;
    height: 1.2rem;
    box-shadow: none;     
}
.btn{
    background-color: goldenrod ;
    border-radius: 0.3rem;
    outline: none;   
    width: 15%;
    color: white ;
    font-size: 1.1rem;
    padding: 0.3rem;
    margin-bottom: 0.4rem;
}
.file{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: dotted black;
   padding: 1rem;
   margin: 1rem;
}
input{
    outline: none;
    color: black;
}
input::placeholder{
    color: rgb(124, 124, 124);
}
.size{
    font-size: 8rem;

}


.form_check_input {
    width: 1.2rem;
    height: 1.2rem; 
    box-shadow: none;
}

.form_check_input:focus {
    box-shadow: none;
}

@media screen and (max-width: 768px) {
    /* Adjust font sizes, paddings, margins, etc. to fit smaller screens */
.back{
    position: absolute;
    top: 28%;
    width: 90%;
    left: 4%;
    border-radius: 1rem;
    padding: 2rem;
    
}
.form{
    margin-left: 2.4rem;
}
}
