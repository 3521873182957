.chat-message {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
}

.chat-message-left {
  justify-content: flex-start;
  flex-direction: row;
}

.chat-message-right {
 
  flex-direction: row-reverse;
}

.avatar-left,
.avatar-right {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.avatar-right {
  margin-left: 10px;
  margin-right: 0;
}

.document-container {
  position: relative;
  display: inline-block;
  max-width: 100%;
  text-align: center;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  background-color: #f8f9fa;
  margin: 5px;
}

.document-preview {
  width: 100px; /* Adjust as necessary */
  height: 100px; /* Adjust as necessary */
  background-color: #e9ecef;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  color: #6c757d;
  margin: 10px 0;
  overflow: hidden;
  border-radius: 5px;
}

.document-thumbnail:hover .download-btn {
  opacity: 1;
}

.document-thumbnail img {
  max-width: 100%;
  max-height: 100%;
}

.document-thumbnail .icon {
  font-size: 3em;
}

.download-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.download-btn:hover {
  background-color: #0056b3;
}

.progress-container {
  position: relative;
  width: 100%;
  height: 10px;
  margin-top: 8px;
  background-color: #ddd;
  border-radius: 4px;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: goldenrod;
  border-radius: 4px;
}

.timestamp {
  position: absolute;
  bottom: 0px;
  right: 5px;
  font-size: 0.8em;
  color: black;
 
  padding: 2px 5px;
  border-radius: 3px;
}

