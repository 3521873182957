.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 370px;
  background-color: white;
  border-radius: 15px;
  padding: 0px;

}

.top {
  display: flex;
  justify-content: center;
  width: 89%;
  max-width: 89%;
  height: 50px;
  border-radius: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  margin-top: 1px;
  margin-bottom: 3px;
  
}

.img1,
.img2 {
  margin-left: 5px;
  margin-top: 1px;
  height: 50px;
  width: 50px;
  border-radius: 35px;
  object-fit: contain;
}

.heading {
  font-size: 20px;
  margin-top: 9px;
  margin-right: 15px;
  color: rgb(54, 50, 50);
  font-weight: 500;
}

.iphone {
  width: auto;
  height: 450px; 
  max-width: 100%;
  border-radius: 30px;
}
.screen {
  /* ECE5DD */
  background: #ECE5DD;
  position: relative;
  overflow:auto;
  scrollbar-width: none;
  top: -385px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 54%;
  max-width: 54%;
  margin-right: 84px;
  max-height: 320px;
  height: 320px;
  scrollbar-width: thin;
  
}
.screen::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  height: 2px;
}
.home-button {
  position: relative;
  width: 90px;
  height: 40px;
  border: 2px solid goldenrod;
  background-color: goldenrod;
  color: rgb(248, 229, 229);
  border-radius: 14px;
  font-size: 19px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.circular-progress {
  position: absolute;
  color: white;
}
.bottom {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 70%;
  max-width: 350px;
  padding: 0px 20px;
  margin-top: -318px;
  justify-content: space-between;
  margin-left: 67px;
}

.sms-bubble {
  position: relative;
  background-color: #ffffff;
  max-height: fit-content;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: -70px;
  margin-bottom: 20px;
  margin-right: 15px;
  margin-left: -15px;
  max-width: 80%;
 
  
}

.bubble {
  content: '';
  position: relative;
  top: -70px;
  width: min-content;
  border-width: 15px 15px 0;
  border-style: solid;
  left: 2px;
 
  border-color: #ffffff transparent transparent;
}

/* Media Query for devices with smaller screens */
@media only screen and (max-width: 600px) {
  .top {
    width: 95%;
  }
}
