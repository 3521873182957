/* DataTable.module.css */

.intro {
    height: 100%;
  }
  
  .bg-image {
    background-color: #6095F0;
  }
  
  .mask-custom {
    background: rgba(24, 24, 16, .2);
    border-radius: 2em;
    backdrop-filter: blur(25px);
    border: 2px solid rgba(255, 255, 255, 0.05);
    background-clip: padding-box;
    box-shadow: 10px 10px 10px rgba(46, 54, 68, 0.03);
  }
  
  .card {
    border-radius: .5rem;
    background-color: #f5f7fa;
  }
  
  .table-responsive {
    overflow-x: auto;
  }
  
  .table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .table th,
  .table td {
    text-align: left;
    padding: .75rem;
    vertical-align: top;
  }
  
  .table th {
    border-top: 0;
  }
  
  .table th,
  .table td {
    border-right: 0;
  }
  
  .table th:last-child,
  .table td:last-child {
    border-right: 1px solid rgba(0, 0, 0, .1);
  }
  
  .table th:first-child,
  .table td:first-child {
    padding-left: 10px;
  }
  
  .table th:last-child,
  .table td:last-child {
    padding-right: 10px;
  }
  