@import '../src/Style/custom.scss'; /* Import custom Bootstrap styles */
@import 'tailwindcss/base'; /* Import Tailwind base styles */
@import 'tailwindcss/components'; /* Import Tailwind components */
@import 'tailwindcss/utilities'; 
/* Import Tailwind utilities */


body {
  margin: 0;
   align-items: center;
  font-family:"Poppins" -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
