/* src/components/TermsOfService.css */

.terms-of-service {
    margin: 20px;
    font-family: Arial, sans-serif;
}

.terms-of-service h1, .terms-of-service h2 {
    color: #333;
}

.terms-of-service p, .terms-of-service ul {
    color: #666;
}

.terms-of-service ul {
    list-style-type: disc;
    margin-left: 20px;
}
