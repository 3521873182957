/* Overlay with a slight animation for smooth appearance */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

/* Keyframes for fade-in animation */
@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

/* Content with smooth pop-up effect */
.popup-content {
  background: #ffffff;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  animation: popIn 0.3s ease-in-out;
}

/* Keyframes for pop-in animation */
@keyframes popIn {
  from {
      transform: scale(0.7);
      opacity: 0;
  }
  to {
      transform: scale(1);
      opacity: 1;
  }
}

/* Heading style */
.popup-content h2 {
  margin: 0;
  font-size: 24px;
  color: #333333;
  font-weight: bold;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

/* Close button with improved style */
.popup-close-button {
  margin-top: 20px;
  padding: 12px 24px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease-in-out, transform 0.2s ease;
}

/* Hover and active states for close button */
.popup-close-button:hover {
  background: #0056b3;
  transform: scale(1.05);
}

.popup-close-button:active {
  background: #003d80;
  transform: scale(0.95);
}
