/* chatinputs.css */

.chat-input {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.icon {
  font-size: 24px;
  cursor: pointer;
  margin: 0 5px;
  color: #666;
}

.text-input {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 20px;
  background-color: #f0f0f0;
  margin: 0 5px;
  font-size: 16px;
}

.text-input:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.audio-preview {
  margin-top: 10px;
  width: 100%;
  text-align: center;
}

.preview-container {
  position: fixed;
  bottom: 4%;
  right: 12%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.preview-container img {
  max-width: 80px;
  max-height: 80px;
  margin-right: 10px;
}

.preview-file {
  margin: 0;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.send-button,
.close-button {
  padding: 5px 10px;
  margin-right: 10px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.send-button:hover,
.close-button:hover {
  background-color: #0056b3;
}

.popup-modal {
  position: fixed;
  bottom: 4%;
  right: 12%;
  transform: translate(-50%, -50%);
  background-color: #f8d7da;
  color: #721c24;
  padding: 15px;
  border-radius: 8px;
  z-index: 1000;
}

.popup-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
