/* toolbar.css */
.toolbar {
  position: relative; /* Allows dropdown to be positioned absolutely within the toolbar */
  background-color: #25D366;
  padding: 10px;
  color: white;
  width: auto;
  height: 5%;
  z-index: 1000; /* Ensure toolbar is above other content */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
}

.toolbar-button {
  /* Add styles for toolbar buttons if needed */
}

/* Dropdown as a sheet */
.dropdown-sheet {
  position: absolute;
  top: 100%; /* Position it just below the toolbar */
  left: 0;
  
   width: 20%;
  max-height: 300px; /* Adjust the height as needed */
  background-color: white;
  border-top: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  overflow-y: auto; /* Makes the dropdown scrollable */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: translateY(0);
  border-radius: 10px;
  opacity: 1;
}

/* Optional: Styling for the dropdown content */
.dropdown-content {
  padding: 10px;
}

/* Styling for each item in the dropdown */
.card-container {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions {
  display: flex;
  align-items: center;
}

.delete-icon {
  cursor: pointer;
  margin-left: 10px;
}

/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Inter', sans-serif;
}

.dndflow {
  display: flex;
  width: 100%;
  height: 82vh;
}

.reactflow-wrapper {
 
  height: 100%;
  width: 100%;
  background-color: #f0f0f0;
}

.sidebar {
  padding: 10px;
  border-radius: 10px;
  background-color: #25D366;
  
  margin-top: 10px;
  margin-left: 10px;
  
}

.dndnode {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #222;
  background-color: #fff;
  cursor: grab;
}

.text-node, .url-buttons-node, .trigger-node {
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.text-node {
  background-color: #f3f4f6;
}

.url-buttons-node {
  background-color: #fef3c7;
}

.trigger-node {
  background-color: #d1fae5;
}

.hand-cursor {
  cursor: grab;
}

.hand-cursor:active {
  cursor: grabbing;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  margin-left: 2px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider, .slider2 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before, .slider2:before {
  position: absolute;
  content: "";
  left: 3px;
  background-color: white;
  transition: .4s;
}

.slider:before {
  height: 14px;
  width: 14px;
  bottom: 3px;
}

.slider2:before {
  height: 8px;
  width: 8px;
  bottom: 2px;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 1px; /* Adjust the width as needed */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5); /* Color of the scrollbar */
  border-radius: 10px; /* Rounded corners */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); /* Background of the scrollbar track */
}

input:checked + .slider, input:checked + .slider2 {
  background-color: #A66CFF;
}

input:checked + .slider:before, input:checked + .slider2:before {
  transform: translateX(14px);
}

.slider.round, .slider2.round {
  border-radius: 34px;
}

.slider.round:before, .slider2.round:before {
  border-radius: 50%;
}

.edgebutton {
  cursor: pointer;
  background: red;
  border: none;
  color: white;
  font-size: 12px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
}

.edgebutton-foreignobject {
  pointer-events: none;
}

.edgebutton-foreignobject div {
  pointer-events: all;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
