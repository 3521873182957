/* src/components/PrivacyPolicy.css */

.privacy-policy {
    margin: 20px;
    font-family: Arial, sans-serif;
}

.privacy-policy h1, .privacy-policy h2 {
    color: #333;
}

.privacy-policy p, .privacy-policy ul {
    color: #666;
}

.privacy-policy ul {
    list-style-type: disc;
    margin-left: 20px;
}
