.container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
    height: 85vh;
    /* padding-left: 10px; */
    /* padding: 0px 20px;  */
    /* background-color: aliceblue; */
    opacity: 0.9;
    overflow-y: scroll;
    padding-top: 20px;
}
/* App.css */

.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
    margin-left: 25px;
    margin-right: 25px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: goldenrod;
    transition: 0.4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: goldenrod;
  }
  
  input:checked + .slider:before {
    transform: translateX(14px);
  }
  
.title {
    font-family: Arial, sans-serif;
    color: #474242;
    font-weight: 100;
    font-size: 26px;
    margin-bottom: 35px; 
    padding-bottom: 5px;
    border-bottom: 2px solid goldenrod;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.input-row {
    display: flex;
    justify-content: space-between; 
    width: 100%;
}
.input-row2 {
    display: flex;
    /* justify-content: space-between;  */
    width: 100%;
    max-width: 100%;
}

.labelT, .labelT1 {
    font-family: Arial, sans-serif;
    font-size: 21px;
    font-weight:540;
    color: #181717;
}

.character-count {
    font-size: 14px;
    color: #181717;
}
.group{
    width: 100%;
    padding-bottom: 25px;
    border-bottom: 2px solid goldenrod;
    border-radius: 1px;
    margin-bottom: 20px;

  
   
}
.lab2{
    margin-top: 14px;
    color: #181717;
}
.input-field,  .language-dropdown{
    width: 100%; 
    padding: 10px;
    font-size: 18px;
    margin-top: 5px; 
    border: 1px solid goldenrod;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    font-family: Arial, sans-serif;
    font-weight: 540;
    color: #111111;
}
.input-field2 {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    margin-top: 5px;
    border: 1px solid goldenrod;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    font-family: Arial, sans-serif;
    font-weight: 540;
    color: #111111;
    outline: none; /* Add this line to remove the black border when focused */
  }
  
.input-field5{
    width: 100%; 
    padding: 10px;
    font-size: 18px;
    margin-top: 5px; 
    border: 1px solid goldenrod;
    border-radius: 5px;
    background-color: #fff;
    font-family: Arial, sans-serif;
    font-weight: 540;
    color: #585151;
}
.txt{
    font-size: 18px;
    background-color: #fff;
    font-family: Arial, sans-serif;
    font-weight: 540;
    color: #585151;
    width: 34%;
    overflow: hidden;
    position: absolute;
    margin-top: -36px;
    margin-left: 127px;
}
.input-field3{
    width: 96%; 
    padding: 10px;
    font-size: 16px;
    margin-top: 5px; 
    font-size: 18px;
    border: 1px solid goldenrod;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    font-family: Arial, sans-serif;
    font-weight: 900px;
    color: #5c5656;
}

.language-dropdown1 {
    width: 50%; 
    padding: 10px;
    font-size: 17px;
    margin-top: 5px;
    margin-right: 3px;
    border: 1px solid goldenrod;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    font-family: Arial, sans-serif;
    color: #5c5656;
}

.language-dropdown1:hover,
.language-dropdown1:focus {
    border-color: #007bff; /* Change border color on hover/focus */
    box-shadow: 0 0 0 2px #007bff1a; /* Add box shadow on hover/focus */
}
.language-dropdown,.language-dropdown1 {
    height: 44px; /* Increase height for better touch targets */
}


.radio-container {
    display: flex;
    flex-wrap: wrap; 
}

.radio-label {
    margin-right: 20px;
font-weight: 500;
    font-size: 17px; 
    color: #181717;
}

.bturlDiv {
    width: 50%;
    max-width: 46%;
    justify-content: space-between;
    margin-top: 10px; 
    flex-direction: row;
}

.subheading{
    font-size: 18px;
    font-family: Arial, sans-serif;
    font-weight:540;
    color: #181717;
}
.subheading2{
    font-size: 17px;
    font-family: Arial, sans-serif;
    font-weight: 540;
    color: #181717;
}
.upload-button{
    width: 95%;
    height:40px;
    background-color: goldenrod;
    border: 2px;
    border-radius: 5px;
    color: white;
    font-size: 22px;
    margin-left: 25px;
}
.upload-button-container{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    align-self: center;
    align-items: center;
    
}